import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ContentWrapper from './ContentWrapper';
import { D } from '@dayetopia/library';
import sassy from '../images/hips-girl.png';
import medIcon from '../images/cross-image.png';
import phoneIcon from '../images/phone-image.png';
import alarm from '../images/alarm-icon.png';
import Button from './Button';

export default function IntroToQuestionaire() {
  return (
    <Container>
      <LayoutStyled>
        <SassyImageStyled src={sassy} />
        <H2Styled>The pill should make your life easier, not harder</H2Styled>
        <PStyled3>
        With Daye, finding the right contraceptive pill for your body is just a questionnaire away.
        </PStyled3>
        <MiddleWrapper>
          <InnerContainer>
            <CrossPhoneIcon src={medIcon} />
            <PStyled>DOCTORS ARE ONLY REQUIRED TO CHECK YOUR:</PStyled>
            <ButtonBox>
              <GreenBox doctorDoes={false}>BMI</GreenBox>
              <GreenBox doctorDoes={false}>Blood pressure</GreenBox>
              <GreenBox doctorDoes={false}>Medical history</GreenBox>
            </ButtonBox>
            <PStyled4>
            In a standard GP appointment it can be hard to find the time for more in-depth questions about your contraceptive preference.
            </PStyled4>
          </InnerContainer>
          <InnerContainer>
            <CrossPhoneIcon src={phoneIcon} />
            <PStyled>
              {' '}
              OUR SECURE QUESTIONNAIRE LOOKS AT{' '}
              <span style={{ fontWeight: 'bold' }}>
                EVERYTHING A DOCTOR WOULD
              </span>,{' '}
              PLUS:
            </PStyled>
            <ButtonBox>
              <GreenBox doctorDoes={false}>Your sexual health</GreenBox>
              <GreenBox doctorDoes={false}> Current medications</GreenBox>
              <GreenBox doctorDoes={false}> Symptoms</GreenBox>
              <GreenBox doctorDoes={false}>Lifestyle</GreenBox>
              <GreenBox doctorDoes={false}> Contraceptive preferences</GreenBox>
              <PStyled4>
                Take it in your own time - no appointment needed.
              </PStyled4>
            </ButtonBox>
            <Link to="/questionnaire-landing">
              <Button>Start now</Button>
            </Link>
          </InnerContainer>
        </MiddleWrapper>
        <AlarmBox>
          <AlarmIcon src={alarm} />
          <PStyled2>
            What we recommend is based on what you tell us — we don't have a
            preferred pill
          </PStyled2>
        </AlarmBox>
      </LayoutStyled>
    </Container>
  );
}

const PStyled = styled(D.P)`
  margin: 20px 0;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const PStyled4 = styled(D.P)`
  font-size: 18px;
  margin: 20px 0;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
const PStyled3 = styled(D.P)`
  text-align: center;
  font-size: 22px;
  margin: auto;
  width: 90%;
  @media only screen and (max-width: 500px) {
    font-size: 18px;
    width: 70%;
  }
`;

const MiddleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 60px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
const InnerContainer = styled.div`
  width: 46%;
  @media only screen and (max-width: 500px) {
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
`;

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
`;

const Container = styled.div`
  width: 100%;
  background-color: #c4e4c1;
  padding-bottom: 100px;
  @media only screen and (max-width: 500px) {
    padding-bottom: 0;
    padding-top: 40px;
  }
`;

const H2Styled = styled(D.H2)`
  font-size: 40px;
  text-align: center;
  margin: 25px auto;
  width: 65%;
  @media only screen and (max-width: 500px) {
    font-size: 28px;
    padding: 0 30px;
    line-height: 44px;
    width: 100%;
    margin-top: 30px;
  }
`;

const SassyImageStyled = styled.img`
  max-width: 250px;
  margin: auto;
  @media only screen and (max-width: 500px) {
    max-width: 55%;
  }
`;

const CrossPhoneIcon = styled.img`
  height: 120px;
  @media only screen and (max-width: 500px) {
    width: auto;
    height: 125px;
    margin-bottom: 10px;
  }
`;
const AlarmIcon = styled.img`
  width: 64px;
  padding-right: 20px;
`;

const GreenBox = styled.div`
  display: inline-block;
  width: auto;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 16px;
  border: ${(props: GreenBoxProps) =>
    props.doctorDoes ? '2px solid #01341a' : '2px solid #fffae3'};
  background-color: #e0eedf;
  border-radius: 7px;
  font-family: Brownstd;
  width: fit-content;
  font-size: 16px;
  color: #01341a;
`;
type GreenBoxProps = {
  doctorDoes: boolean;
};

const ButtonBox = styled.div`
  @media only screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const AlarmBox = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  border: 2px solid #01341a;
  background-color: ${D.creamGold};
  margin: auto;
  margin-top: 100px;

  @media only screen and (max-width: 500px) {
    width: 90%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 50px;
  }
`;

const PStyled2 = styled(D.P)`
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #01341a;
  font-size: 18px;
  line-height: 34px;
  text-decoration: none;
  text-align: center;
`;
