import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';

import ContentWrapper from './ContentWrapper';
import { D } from '@dayetopia/library';
import portrait from '../images/portrait.png';
import womb from '../images/womb.png';
import shield from '../images/shield.png';
import cross from '../images/cross-icon.png';
import pill from '../images/pill-icon.png';
import jug from '../images/science-jug.png';
import PopUpModal from './PopUpModal';

export default function CREInfo() {
  const [modalOpen, setModalOpen] = useState(false);
  const [popupNum, setPopupNum] = useState(0);

  const modalFunc = num => {
    setPopupNum(num);
    setModalOpen(true);
  };
  return (
    <Container>
      <LayoutStyled>
        <FlexContainer>
          <ImageContainer>
            <ImageStyled src={portrait} />
          </ImageContainer>
          <InfoWrapper>
            <H2Styled>Our contraception consultation has been: </H2Styled>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={0.9}
              animateOnce={true}
              initiallyVisible={true}
            >
              <BulletPoint>
                <IconWrapper>
                  <img alt="womb logo" style={{ height: '26px' }} src={womb} />
                </IconWrapper>
                <PStyled>
                  Designed by Daye's team of gynaecologists, GPs and doctors
                  specialising in women's health.
                </PStyled>
              </BulletPoint>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={1.2}
              animateOnce={true}
              initiallyVisible={true}
            >
              <BulletPoint>
                <IconWrapper>
                  <img
                    alt="shield logo"
                    style={{ height: '40px' }}
                    src={shield}
                  />
                </IconWrapper>
                <PStyled>
                  Reviewed by{' '}
                  <D.Link href="https://www.fsrh.org/home/">
                    The Faculty of Sexual & Reproductive Healthcare
                  </D.Link>
                </PStyled>
              </BulletPoint>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={1.5}
              animateOnce={true}
              initiallyVisible={true}
            >
              <BulletPoint>
                <IconWrapper>
                  <img
                    alt="cross logo"
                    style={{ height: '40px' }}
                    src={cross}
                  />
                </IconWrapper>
                <PStyled>Approved by two physician groups.</PStyled>
              </BulletPoint>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={1.8}
              animateOnce={true}
              initiallyVisible={true}
            >
              <BulletPoint>
                <IconWrapper>
                  <img alt="pill logo" style={{ height: '40px' }} src={pill} />
                </IconWrapper>
                <PStyled>
                  Executed in partnership with prescribing physicians.
                </PStyled>
              </BulletPoint>
            </ScrollAnimation>
            <PStyled2 onClick={() => modalFunc(3)}>
              <img
                alt="jug"
                style={{
                  height: '26px',
                  paddingRight: '10px',
                }}
                src={jug}
              />
              Learn more about how our consultation was developed
            </PStyled2>
          </InfoWrapper>
        </FlexContainer>
        <PopUpModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          popupNum={popupNum}
        />
      </LayoutStyled>
    </Container>
  );
}

const ImageContainer = styled.div`
  width: 40%;
  margin: 0;
  text-align: center;
  @media only screen and (max-width: 500px) {
    width: 85%;
  }
`;

const IconWrapper = styled.div`
  width: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PStyled = styled(D.P)`
  margin-top: 0;
  margin-bottom: 0;
  max-width: 80%;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-left: 20px;
`;

const BulletPoint = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const FlexContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-around;
  display: flex;
  margin-bottom: 120px;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
`;

const InfoWrapper = styled.div`
  width: 40%;
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  width: 92%;
`;

const ImageStyled = styled.img`
  max-width: 80%;
  @media only screen and (max-width: 500px) {
    margin: auto;
  }
`;
const Container = styled.div`
  width: 100%;
  background-color: #e0eedf;
  padding-top: 100px;

  @media only screen and (max-width: 500px) {
    padding: 0 20px 50px;
  }
`;

const H2Styled = styled(D.H2)`
  margin-top: 0;
  font-size: 40px;
  text-transform: uppercase;
  text-align: left;
  font-family: Brownstd;
  color: #01341a;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media only screen and (max-width: 500px) {
    text-align: center;
    margin-top: 50px;
    font-size: 22px;
  }
`;

const PStyled2 = styled(D.P)`
  margin-bottom: 50px;
  font-size: 16px;
  text-align: center;
  font-family: BrownStd;
  width: fit-content;
  border: 2px solid #01341a;
  margin-top: 50px;
  display: flex;
  align-items: center;
  padding: 13px 20px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
