export const PopUpModalData = {
    0: ['Will it make me infertile?',
        `Nope! It might actually help you conceive. Although it is common to experience 
        a 1-2 month delay in conceiving immediately after ceasing the contraceptive pill, pregnancy rates at 1 year are 
        the same as women who previously used condoms. A 2002 study showed that women who took the pill for over 5 years 
        were more likely to have conceived after 12 months than those who were on the pill for shorter periods of time!`],
    1: ['Is it safe to take the pill without a break?',
        `You've probably heard that taking the pill continuously is 
        bad for your health - that's not true though. The Faculty of Sexual & Reproductive Healthcare (which sets 
        the standards for contraception in the UK) have reviewed the data and report that there are no significant 
        differences in the blood hormones or blood pressure between women taking the pill for 1 year, and those who 
        are not on the pill. Blood sugar and lipid levels are similar too.`],
    2: ['Does the pill cause cancer?',
        `Hmmm...It’s complicated. But overall no!
         Although it may slightly increase the risk of breast and cervical cancer, 
         the pill decreases the risk of overall cancer (including womb, ovarian and colon cancer).`],
    3: ['', `This service was developed to provide a personalised approach to contraception prescriptions. The majority 
         of contraceptives in the UK are prescribed by general practitioners (GPs), who usually have just 10 minutes to complete 
         the prescription. This is not much time for women to provide their health context, or for a practitioner to provide enough
         guidance.Daye’s service using guidelines from the Faculty of Sexual and Reproductive Health (FSRH), and all available clinical 
         evidence on which pill benefits which woman the most. By asking in-depth questions on health history, lifestyle and personal 
         preference Daye’s consultation creates a detailed profile of each user. This information is then used to generate a recommendation. 
         Prescribing physicians review the detailed health profile and pill recommendation before creating a subscription.`],
    4: ['Mood swings', `Research suggests that the contraceptive pill may cause negative mood changes for some women, though there is 
         no clear evidence to demonstrate that the pill causes depression. More research is needed, but its definitely something to know 
         about and be aware of when starting a new method.`],
    5: ['Acne', `Some contraceptive pills have been shown to help reduce acne, by reducing levels of androgens (scientific name for 
        testosterone etc). Some pills are better than others, and work better for women with severe acne.`],
    6: ['Excess Hair', `Male-pattern hair growth (usually upper lip, chest or thighs) can be a sign of higher levels of androgens 
        (scientific name for testosterone etc) or PCOS (polycystic ovary syndrome) - the right contraceptive pill can help!`],
    7: ['Loss of libido', `It’s normal for libido to fluctuate, and sexual desire is influenced by many factors, not just what contraceptive you’re on. However, there is some research to 
        suggest that different types of the pill affect libido differently.`],
    8: ['Weight gain', `Although some other methods of contraceptive are associated with weight gain, studies have not shown that the 
        contraceptive pill causes weight gain. Here's to scientists agreeing for once!`],
    9: ['Breakthrough bleeding', `Although all contraceptive pills help with irregular or unpredictable cycles, one of the common 
        side effects is unscheduled or 'breakthrough' bleeding. Some pills are better for reducing this for some women though.`]
};
