import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import Button from './Button';
import ContentWrapper from './ContentWrapper';
import mainImageThreeGirls from '../images/cre-main-image.png';
import { Link } from 'gatsby';

export default function Landing() {
  return (
    <Container>
      <LayoutStyled>
        <LeftContainer>
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={0.8}
            animateOnce={true}
          >
            <H1Styled>Worried about the side effects of the pill?</H1Styled>
            <H2Styled>
            Take Daye’s consultation and minimise side effects with the right prescription.
            </H2Styled>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={1}
            animateOnce={true}
          >
            <Link to="/questionnaire-landing">
              <Button>Get started</Button>
            </Link>
          </ScrollAnimation>
        </LeftContainer>
        <ImageContainer>
          <MainImage src={mainImageThreeGirls} />
        </ImageContainer>
      </LayoutStyled>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: #c4e4c1;

  @media only screen and (max-width: 500px) {
    padding-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 130%;
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const MainImage = styled.img`
  @media only screen and (max-width: 500px) {
    margin: auto;
    margin-top: 30px;
  }
  @media only screen and (max-width: 360px) {
    margin-top: 10px;
  }
`;
const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: 80vh;
  max-width: 1620px;
  align-items: center;
  width: 85%;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    padding-top: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
`;
const LeftContainer = styled.div`
  width: 100%;

  @media only screen and (max-width: 500px) {
    padding: 40px;
    text-align: center;
  }
  @media only screen and (max-width: 360px) {
    margin-top: 73px;
  }
`;
const H1Styled = styled(D.H1)`
  max-width: 90%;
  font-size: 50px;
  font-weight: 500;
  @media only screen and (max-width: 500px) {
    font-size: 27px;
    text-align: center;
    margin: 10px auto;
    max-width: 100%;
  }
`;

const H2Styled = styled(D.H2)`
  font-size: 24px;
  max-width: 90%;
  margin-top: 30px;
  margin-bottom: 50px;
  line-height: 34px;
  font-family: Brownstd;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 20px auto 30px;
    max-width: 100%;
  }
`;
