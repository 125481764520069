import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { D } from '@dayetopia/library';
import ContentWrapper from './ContentWrapper';
import slidersIcon from '../images/sliders-icon.png';
import stampIcon from '../images/stamp-icon.png';
import controlIcon from '../images/control-icon.png';

export default function BenefitsOfDaye() {
  return (
    <Container>
      <H2Styled>Subscriptions tailored to you</H2Styled>
      <TileContainer>
        <ScrollAnimation
          animateIn="fadeInUp"
          duration={0.5}
          animateOnce={true}
        >
          <BenefitsTile>
            <IconStyled src={slidersIcon}></IconStyled>
            <H3Styled>Personalised</H3Styled>
            <PStyled>
              {' '}
              We’ll find a pill to suit your needs and minimise side effects. Just tell us about your body and lifestyle.
              </PStyled>
          </BenefitsTile>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
          <BenefitsTile>
            <IconStyled src={stampIcon}></IconStyled>
            <H3Styled>Subscription</H3Styled>
            <PStyled>
              Get a new pack delivered every 3 months. No more appointments or running out of pills.
            </PStyled>
          </BenefitsTile>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          duration={1.5}
          animateOnce={true}
        >
          <BenefitsTile>
            <IconStyled src={controlIcon}></IconStyled>
            <H3Styled>In control</H3Styled>
            <PStyled>
              Keep track of how you’re feeling — if it’s not working out we will help you find an alternative.
            </PStyled>
          </BenefitsTile>
        </ScrollAnimation>
      </TileContainer>
    </Container>
  );
}

const PStyled = styled(D.P)`
  font-size: 16px;
  line-height: 26px;
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 95px 0 15px;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const H3Styled = styled(D.H3)`
  width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  font-family: Brownstd;
  margin: 20px 0;
`;
const Container = styled.div`
  padding: 80px 20px;
  background-image: linear-gradient(180deg, #e0eedf, #e0eedf 64%, #fffae3);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e0eedf;
  @media only screen and (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const H2Styled = styled(D.H2)`
  margin-top: 0;
  font-size: 40px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 54px;
  }
`;

const BenefitsTile = styled.div`
  width: 90%;
  text-align: center;
  margin: auto;
  @media only screen and (max-width: 500px) {
    width: 85%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

const IconStyled = styled.img`
  height: 60px;
`;
