import React, { useState } from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import ContentWrapper from './ContentWrapper';
import mood from '../images/mood-icon.png';
import acne from '../images/acne-icon.png';
import hair from '../images/hair-icon.png';
import libido from '../images/libido-icon.png';
import weight from '../images/weight-icon.png';
import PopUpModal from './PopUpModal';

export default function SideEffects() {
  const [moodDrawerOpen, setMoodDrawerOpen] = useState(false);
  const [acneDrawerOpen, setAcneDrawerOpen] = useState(false);
  const [hairDrawerOpen, setHairDrawerOpen] = useState(false);
  const [libidoDrawerOpen, setLibidoDrawerOpen] = useState(false);
  const [weightDrawerOpen, setWeightDrawerOpen] = useState(false);
  const [bleedingDrawerOpen, setBleedingDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [popupNum, setPopupNum] = useState(0);

  const modalFunc = num => {
    setPopupNum(num);
    setModalOpen(true);
  };

  return (
    <Container>
      <LayoutStyled>
        <H2Styled>What about the side effects of the pill?</H2Styled>
        <PStyled>
          The side effects are real; 60% of women stop the pill after 6 months
          because of them. They can happen with any pill - it just depends how{' '}
          <span style={{ fontStyle: 'italic' }}>your</span> body reacts.
        </PStyled>
        <BoxContainer>
          <PopUpModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            popupNum={popupNum}
          />
          <SideEffectBox onClick={() => modalFunc(4)}>
            <IconStyled src={mood} />
            <H3Styled>Mood swings</H3Styled>
          </SideEffectBox>

          <SideEffectBox onClick={() => modalFunc(5)}>
            <IconStyled src={acne} />
            <H3Styled>Acne</H3Styled>
          </SideEffectBox>

          <SideEffectBox onClick={() => modalFunc(6)}>
            <IconStyled src={hair} />
            <H3Styled>Excess hair</H3Styled>
          </SideEffectBox>

          <SideEffectBox onClick={() => modalFunc(7)}>
            <IconStyled src={libido} />
            <H3Styled>Loss of libido</H3Styled>
          </SideEffectBox>

          <SideEffectBox onClick={() => modalFunc(8)}>
            <IconStyled src={weight} />
            <H3Styled>Weight gain</H3Styled>
          </SideEffectBox>

          <SideEffectBox onClick={() => modalFunc(9)}>
            <IconStyled src={acne} />
            <H3Styled>Breakthrough bleeding</H3Styled>
          </SideEffectBox>
        </BoxContainer>
        <H3StyledOrange>
          The right pill should make you feel great.
        </H3StyledOrange>
        <PStyled>
          The key is finding the best one for you and taking it in the correct
          way.
        </PStyled>
      </LayoutStyled>
    </Container>
  );
}

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 500px) {
    padding-top: 20px;
  }
`;

const H3StyledOrange = styled(D.H3)`
  color: #e4ac43;
  font-size: 26px;
  line-height: 20px;
  margin-bottom: 10px;
  @media only screen and (max-width: 500px) {
    line-height: 30px;
    width: 85%;
    text-align: center;
  }
`;

const PStyled = styled(D.P)`
  font-size: 18px;
  text-align: center;
  font-weight: 550;
  line-height: 28px;
  width: 62%;
  color: #01341a;
  font-size: 18px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    width: 85%;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #fffae3;
  padding-bottom: 50px;
  padding-top: 40px;
  @media only screen and (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const H2Styled = styled(D.H2)`
  margin: auto;
  margin-bottom: 24px;
  font-size: 40px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    width: 90%;
    font-size: 32px;
    line-height: 44px;
  }
`;

const H3Styled = styled(D.H3)`
  font-family: Brownstd;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
`;

const SideEffectBox = styled.div`
  align-items: center;
  border: 2px solid #e4ac43;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  transform: scaleX(1) scaleY(1);

  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 200ms ease 0s;
    transform: scaleX(1.1) scaleY(1.1);
  }
`;

const BoxContainer = styled.div`
  display: grid;
  height: 100%;
  margin: auto;
  max-width: 1000px;
  margin-bottom: 50px;
  margin-top: 50px;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-template-rows: auto auto;
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    width: 90%;
    max-height: none;
  }
`;

const IconStyled = styled.img`
  height: 24px;
`;
