import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ContentWrapper from './ContentWrapper';
import { D } from '@dayetopia/library';
import flexible from '../images/flexible.png';
import tick from '../images/tick-icon.png';
import boxes from '../images/boxes.png';
import Button from './Button';
import { navigate } from 'gatsby';

export default function Subscription() {
  const screenWidth = window.screen.width;
  const tickBoxWidth = screenWidth > 500 ? '20%' : '80%';

  function goStart() {
    navigate('/checkout/create-account');
  }

  return (
    <Container>
      <LayoutStyled>
        <H2Styled>Affordable and personal</H2Styled>
        <PStyled>£5-10 per month depending on your pill</PStyled>
        <PStyledBackground>Flexible subscription coming soon</PStyledBackground>
        <FourTickContainer>
          <TickScrollAnimation
            animateIn="fadeInUp"
            duration={0.5}
            animateOnce={true}
          >
            <TickItem>
              <TickIcon src={tick} />
              <H2Styled2>delivered to you </H2Styled2>
              <PStyled2> In discrete packaging</PStyled2>
            </TickItem>
          </TickScrollAnimation>
          <TickScrollAnimation
            animateIn="fadeInUp"
            duration={0.8}
            animateOnce={true}
          >
            <TickItem>
              <TickIcon src={tick} />
              <H2Styled2>never run out</H2Styled2>
              <PStyled2> No last pill panic!</PStyled2>
            </TickItem>
          </TickScrollAnimation>

          <TickScrollAnimation
            animateIn="fadeInUp"
            duration={1.1}
            animateOnce={true}
          >
            <TickItem>
              <TickIcon src={tick} />
              <H2Styled2Wide>Automatically renew</H2Styled2Wide>
              <PStyled2>Each new pack comes to you</PStyled2>
            </TickItem>
          </TickScrollAnimation>

          <TickScrollAnimation
            animateIn="fadeInUp"
            duration={1.3}
            animateOnce={true}
          >
            <TickItem>
              <TickIcon src={tick} />
              <H2Styled2Wide>No appointments necessary</H2Styled2Wide>
              <PStyled2>Say goodbye to booking or queuing</PStyled2>
            </TickItem>
          </TickScrollAnimation>
        </FourTickContainer>
        <Link to="/questionnaire-landing">
          <Button>Start now</Button>
        </Link>
      </LayoutStyled>
    </Container>
  );
}

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
`;

const Container = styled.div`
  width: 100%;
  background-color: #fffae3;
  padding-bottom: 100px;
  @media only screen and (max-width: 500px) {
    padding: 0 20px 50px;
  }
`;

const H2Styled = styled(D.H2)`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    font-size: 35px;
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;


const PStyledBackground = styled(D.P)`
  margin-top: 20px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #e0eedf;
  color: rgba(1, 52, 26, 0.56);
  font-size: 18px;
  text-transform: uppercase;
  @media only screen and (max-width: 500px) {
    line-height: 26px;
    font-size: 18px;
    padding: 7px 20px;
    text-align: center;
    margin: 10px 41px;
  }
`;

const H2Styled2 = styled(D.H2)`
  font-weight: 900;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px;
  font-family: Brownstd;
  width: 103px;
  margin-bottom: 20px;
  @media only screen and (max-width: 500px) {
    width: 70%;
    font-size: 15px;
  }
`;

const H2Styled2Wide = styled(D.H2)`
  font-weight: 900;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px;
  font-family: Brownstd;
  width: 169px;
  margin-bottom: 20px;
  @media only screen and (max-width: 500px) {
    width: 70%;
    font-size: 15px;
  }
`;

const TickItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin: auto;
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
`;

const TickIcon = styled.img`
  width: 40px;
  margin-bottom: 20px;
`;

const FourTickContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 50px;
  width: 90%;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin: 25px 0 0;
  }
`;

const TickScrollAnimation = styled(ScrollAnimation)`
  flex-basis: 0;
  flex-grow: 1;
`;

const PStyled = styled(D.P)`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
`;

const PStyled2 = styled(D.P)`
  font-size: 16px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
`;
