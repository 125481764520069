import React, { useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { D } from '@dayetopia/library';

import IconClose from '../images/cross.svg';
import { PopUpModalData } from './PopUpModalData';
import useBlockScroll from './useBlockScroll';

interface Props {
  modalOpen: boolean;
  setModalOpen: (a: boolean) => void;
  popupNum: number;
}

export default function PopUpModal({
  modalOpen,
  setModalOpen,
  popupNum,
}: Props) {
  const blockScroll = useBlockScroll();
  useEffect(() => {
    blockScroll(modalOpen);
  }, [modalOpen]);
  const title = Object.values(PopUpModalData[popupNum][0]);

  return (
    <Modal
      isOpen={modalOpen}
      contentLabel="Example Modal"
      onRequestClose={() => setModalOpen(false)}
      className="react-modal react-modal-upgrade-box"
      overlayClassName="react-modal-overlay"
      ariaHideApp={false}
      preventScroll={true}
      shouldFocusAfterRender={false}
    >
      <ScrollWapperTablet>
        <IconCloseStyled onClick={() => setModalOpen(false)} />
        <FlexWrapper>
          <TextContainer>
            {title.length > 0 && <H2Styled>{title}</H2Styled>}
            <PStyled3>{Object.values(PopUpModalData[popupNum][1])}</PStyled3>
          </TextContainer>
        </FlexWrapper>
      </ScrollWapperTablet>
    </Modal>
  );
}

const H2Styled = styled(D.H2)`
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
`;

const PStyled3 = styled(D.P)`
  cursor: pointer;
  width: fit-content;
  margin-top: 0px;
  color: #01341a;
  font-weight: 400;
  margin-bottom: 32px;
  line-height: 26px;
  text-align: center;
  font-size: 16px;
  padding: 6px 10px;
  @media only screen and (max-width: 500px) {
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 0px;
    overflow: scroll;
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }
`;

const ScrollWapperTablet = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: block;
    padding-bottom: 20px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  width: 85%;
  overflow-y: scroll;
  animation: fadein 0.8s;
  @media only screen and (max-width: 768px) {
    padding: 0 20px 0 20px;
    overflow-y: hidden;
    margin: 40px auto 0 auto;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const IconCloseStyled = styled(IconClose)`
  position: absolute;
  top: -20px;
  right: 25px;
  &:hover {
    cursor: pointer;
    * {
      fill: #eda91f;
    }
  }
`;
