import { useState } from 'react';

export default function useBlockScroll() {
    const [offSet, setOffSet] = useState(0);

    return function blockScroll(block) {
        const nu = navigator.userAgent.toLowerCase();
        if (nu.indexOf("chrome") != -1) {
            if (block) {
                const offsetY = window.pageYOffset;
                document.body.setAttribute('style', `height: 100vh; overflow: hidden;`);
                document.body.scrollTop = offsetY;
                setOffSet(offsetY);
            } else {
                document.body.setAttribute('style', `height: auto; overflow: visible;`);
                window.scrollTo(0, offSet);
            }
        };
    }
}
