import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import { D } from '@dayetopia/library';
import ContentWrapper from './ContentWrapper';
import medicalBoardImg from '../images/med-board.png';

export default function MedicalAdvisoryBoard() {
  return (
    <Container>
      <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
        <LayoutStyled>
          <img
            alt="medical logo"
            style={{ width: '200px' }}
            src={medicalBoardImg}
          />
          <H2Styled>Our Medical Advisory Board</H2Styled>
          <DoctorInfoContainer>
            <DoctorTile>
              <H3Styled>Prof. iain buchan</H3Styled>
              <PStyled>
                Former Director of Healthcare Research Microsoft, current
                Clinical Professor in Public Health Informatics University of
                Liverpool
              </PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Prof. Dharani Hapangama</H3Styled>
              <PStyled>
                Professor of Gynaecology University of Liverpool, Gynaecology
                Surgeon Liverpool Women’s Hospital
              </PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Prof. Phil Bennett</H3Styled>
              <PStyled>
                Professor of Obstetrics & Gynaecology, Imperical College London,
                practising OBGYN
              </PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Prof. Louise Kenny</H3Styled>
              <PStyled>
                Executive Pro-Vice Chancellor University of Liverpool
              </PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Dr. Nicola Tempest</H3Styled>
              <PStyled>Research Fellow Liverpool Women’s Hospital</PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>dr. Benjamin Viaris de Lesegno</H3Styled>
              <PStyled>FRCOG, practising OBGYN</PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Dr. Melanie Bone</H3Styled>
              <PStyled>FACOG, practicing OBGYN</PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>dr. Claire Mellon</H3Styled>
              <PStyled>FRCOG, practising OBGYN</PStyled>
            </DoctorTile>
            <DoctorTile>
              <H3Styled>Dr. Paul Grant</H3Styled>
              <PStyled>Endocrinologist</PStyled>
            </DoctorTile>
          </DoctorInfoContainer>
        </LayoutStyled>
      </ScrollAnimation>
    </Container>
  );
}

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;

const Container = styled.div`
  width: 100%;
  background-image: linear-gradient(180deg, #e0eedf, #c4e4c1);
  padding-bottom: 100px;
  @media only screen and (max-width: 500px) {
    padding: 0 20px 100px 20px;
  }
`;

const H2Styled = styled(D.H2)`
  margin-top: 40px;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  @media only screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 50px;
  }
`;

const DoctorTile = styled.div``;
const PStyled = styled(D.P)`
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  color: #01341a;
  font-size: 18px;
  line-height: 32px;
  text-decoration: none;
`;

const H3Styled = styled(D.H3)`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Brownstd;
`;

const DoctorInfoContainer = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px;
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;
