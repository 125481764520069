import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';

import ContentWrapper from './ContentWrapper';
import { D } from '@dayetopia/library';
import chatting from '../images/chatting.png';
import PopUpModal from './PopUpModal';
import twoPortrait from '../images/two-portraits.png';

export default function Rumours() {
  const [modalOpen, setModalOpen] = useState(false);
  const [popupNum, setPopupNum] = useState(0);
  const screenWidth = window.screen.width;
  const QuestionContainerWidth = screenWidth > 500 ? '50%' : '80%';

  const modalFunc = num => {
    setPopupNum(num);
    setModalOpen(true);
  };

  return (
    <Container>
      <LayoutStyled>
        <ChattingImg src={chatting} />
        <H2Styled>Let's talk about the rumours</H2Styled>
        <PStyled>(we heard them too)</PStyled>
        <MiddleContainer>
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={1.3}
            animateOnce={true}
          >
            <ImageContainer>
              <ImageStyled src={twoPortrait}></ImageStyled>
            </ImageContainer>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={0.9}
            style={{ width: QuestionContainerWidth }}
            animateOnce={true}
          >
            <QuestionsContainer>
              <PopUpModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                popupNum={popupNum}
              />
              <SingleQuestionContainer>
                <Question>WILL IT MAKE ME INFERTILE?</Question>
                <Answer>Nope!</Answer>
                <PStyled3 onClick={() => modalFunc(0)}>Show more</PStyled3>
              </SingleQuestionContainer>
              <SingleQuestionContainer>
                <Question>
                  {' '}
                  IS IT SAFE TO TAKE THE PILL WITHOUT A BREAK?
                </Question>
                <Answer>
                  Yes! There’s no proof that taking the pill continuously is bad
                  for your health.
                </Answer>
                <PStyled3 onClick={() => modalFunc(1)}>Show more</PStyled3>
              </SingleQuestionContainer>

              <SingleQuestionContainer>
                <Question> DOES THE PILL CAUSE CANCER?</Question>
                <Answer>
                  It’s complicated. Some groups may be more at risk, but our
                  consultation screens for risk factors and advises you
                  accordingly. Overall, the pill reduces your risk of cancer.
                </Answer>
                <PStyled3 onClick={() => modalFunc(2)}>Show more</PStyled3>
              </SingleQuestionContainer>
            </QuestionsContainer>
          </ScrollAnimation>
        </MiddleContainer>
      </LayoutStyled>
    </Container>
  );
}

const SingleQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fffae3;
  z-index: 1;
  margin: 20px 0;
  @media only screen and (max-width: 500px) {
    padding-top: 20px;
    margin-top: 0;
    align-items: center;
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: flex-end;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Question = styled(D.P)`
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 18px;
  color: #e4ac43;
  @media only screen and (max-width: 500px) {
    text-align: center;
  }
`;

const Answer = styled(D.P)`
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 18px;
  text-decoration: none;
  @media only screen and (max-width: 500px) {
    text-align: center;
  }
`;

const QuestionsContainer = styled.div`
  overflow: hidden;
  margin: auto;
  @media only screen and (max-width: 500px) {
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  width: 85%;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const PStyled = styled(D.P)`
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  color: rgba(1, 52, 26, 0.56);
  font-size: 18px;
  @media only screen and (max-width: 500px) {
    font-size: 19px;
  }
`;

interface IC {
  open: boolean;
  height: string;
}

export const AnimationContainer = styled.div`
  transition: height 0.3s ease-in-out;
  height: ${(props: IC) => (props.open ? props.height : 0)};
  overflow: hidden;
`;

const PStyled2 = styled(D.P)`
  font-size: 18px;
  position: relative;
  background: #fffae3;
  text-align: left;
  max-width: 90%;
  margin-bottom: 30px;
  margin-top: 0;
  padding-bottom: 30px;
  @media only screen and (max-width: 500px) {
    width: 90%;
    font-size: 16px;
    text-align: center;
    margin: auto;
  }
`;

const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

const ChattingImg = styled.img`
  width: auto;
  max-width: 17%;
  margin-bottom: 0px;
  @media only screen and (max-width: 500px) {
    max-width: 40%;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #fffae3;
  padding-bottom: 100px;
  padding-top: 40px;

  @media only screen and (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const H2Styled = styled(D.H2)`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
    width: 80%;
  }
`;

const ImageStyled = styled.img`
  max-width: 85%;
  margin: auto;
  @media only screen and (max-width: 500px) {
    max-width: 90%;
  }
`;

const PStyled3 = styled(D.P)`
  cursor: pointer;
  width: fit-content;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-top: 6px;
  border-bottom: 2px solid #01341a;
  color: #01341a;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  @media only screen and (max-width: 500px) {
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;
